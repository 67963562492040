<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.document">
			<template #title>Testimonials</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'TestimonialsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="testimonials" item-key="id" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {call, sync} from "vuex-pathify";

export default {
	name: "TestimonialsIndex",
	computed:{
		testimonials: sync('testimonials/testimonials')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Title',
				align: 'start',
				sortable: true,
				value: 'title',
			},
			{
				text: 'Author',
				align: 'start',
				sortable: true,
				value: 'author',
			},
			{
				text: 'Company',
				align: 'start',
				sortable: true,
				value: 'company',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getTestimonials(null)
		},
		editItem(data){
			this.$router.push({name:'TestimonialsEdit', params: {id: data.id}})
		},
		getTestimonials: call('testimonials/getTestimonials')
	}
}
</script>